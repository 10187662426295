"use client"

import { createSupabaseBrowserClient } from "lib/supabase/browserClient"
import { useRouter } from "next/navigation"
import React, { useEffect } from "react"

type AuthProviderProps = {
  /** session.access_token  evaluated from cookies server side */
  accessToken: string | null
  children: React.ReactNode
}

/**
 * Makes sure we call router.refresh() whenever auth state changes and we get a new session token.
 * This prevents users being left on Dashboard view after signout or in Sing in view after successful sign in.
 *
 * Not be needed **if** every auth state change leading event handles redirects explicitly.
 */
const AuthProvider = (props: AuthProviderProps) => {
  const supabase = createSupabaseBrowserClient()
  const router = useRouter()

  useEffect(() => {
    const {
      data: { subscription: authListener },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session?.access_token !== props.accessToken) {
        router.refresh()
      }
    })

    return () => {
      authListener?.unsubscribe()
    }
  }, [props.accessToken, supabase.auth, router])

  return props.children
}

export default AuthProvider
