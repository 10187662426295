"use client"

import Toast from "@ui/molecules/Toast"
import React from "react"
// import * as s from "./ToastProvider.module.scss"

type ToastProviderProps = {
  children: React.ReactNode
}

const ToastProvider = (props: ToastProviderProps) => {
  return (
    <Toast.Provider>
      {props.children}
      <Toast.Viewport />
    </Toast.Provider>
  )
}

export default ToastProvider
