import * as ToastPrimitive from "@radix-ui/react-toast"
import React from "react"
import s from "./Toast.module.css"

type ToastProps = {
  title?: string
  /** The toast message. */
  content: React.ReactNode
  //   children: React.ReactNode;
  closeLabel?: string
}

/**
 * A succinct message that is displayed temporarily.
 */
const Toast = (props: ToastProps) => {
  const {
    title,
    content,
    closeLabel = "X", // TODO icon?
    /*children,*/ ...rootProps
  } = props
  return (
    <ToastPrimitive.Root {...rootProps} className={s.toast}>
      {title && (
        <ToastPrimitive.Title className={s.title}>{title}</ToastPrimitive.Title>
      )}
      <ToastPrimitive.Description className={s.description}>
        {content}
      </ToastPrimitive.Description>
      {/* {children && (
        <ToastPrimitive.Action asChild altText="">
          {children}
        </ToastPrimitive.Action>
      )} */}
      <ToastPrimitive.Close aria-label="Close" className={s.close}>
        <span aria-hidden>{closeLabel}</span>
      </ToastPrimitive.Close>
    </ToastPrimitive.Root>
  )
}

const Viewport = () => <ToastPrimitive.Viewport className={s.viewport} />

const Provider = ToastPrimitive.Provider

export default Object.assign(Toast, { Viewport, Provider })
